<template>
    <rsck-refresh ref="refresh" @onPullDown="onPullDown" @onPullUp="onPullUp">
        <nut-swiper
                class="swiper"
                direction="horizontal"
                :swiperData="banners"
                :loop="true"
        >
            <div
                    v-for="(item, index) in banners"
                    :key="index"
                    class="nut-swiper-slide"
            >
                <img :src="item.img_path" style="width: 100%; height: 100%"/>
            </div>
        </nut-swiper>

        <div class="card-titles">
            <div class="image">
                <img src="../../static/image/icons/hotGoods.png">
            </div>
            <div class="title-text" slot="title">精选礼品卡</div>
        </div>

        <div class="coupon-card-list-container">
            <div class="coupon-card" v-for="(item,index) in couponCardList" @click="goToBuyPage(index)">
                <div class="title">
                    {{item.card_name}}
                </div>
                <div class="cover">
                    <img :src="item.cover"/>
                </div>
                <div class="footer">
                    <div class="product-price">{{item.original_price}}</div>
                    <div class="market-price">
                        <div class="rmb">¥</div>
                        <div class="text">{{item.money}}</div>
                    </div>
                </div>
            </div>
        </div>
    </rsck-refresh>
</template>

<script>

  import CouponCardV1 from '@/template/coupon-card/coupon-card-v1'
  import CellV3 from '@/template/community/cell/cellV3'
  import RsckRefresh from '@/components/common/rsck-refresh'

  export default {
    name: 'lists',
    components: {
      RsckRefresh, CellV3,
      CouponCardV1,
    },
    data () {
      return {
        banners: [],
        couponCardList: [],
        canContinue: false,
      }
    },
    mounted () {
      this.resetData()
      this.canContinue = true
    },
    methods: {
      resetData () {
        this.getFlash()
        this.getCouponCardList()
      },
      onPullDown () {
        //触发下拉属性
        this.resetData()
        setTimeout(() => {
          this.$refs.refresh.endPullDown()
        }, 500)
      },
      onPullUp () {
        //触发上拉加载
        this.getCouponCardList()
        setTimeout(() => {
          this.$refs.refresh.endPullUp(true)
        }, 500)
      },
      getFlash () {
        this.$api.couponCard.getSwiperList().then(response => {
          this.banners = response.data
        })
      },
      getCouponCardList () {
        this.$api.couponCard.getCouponCardCanBuyList().then(response => {
          this.couponCardList = response.data
        })
      },
      goToBuyPage (index) {
        let query = {
          id: this.couponCardList[index].id,
        }
        let options = encodeURIComponent(JSON.stringify(query))
        if (this.canContinue) {
          this.canContinue = false

          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/couponCard/card-buy?query=' + options,
          })
          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      }
    }
  }
</script>

<style scoped lang="scss">
    .swiper {
        width: 100%;
        height: 122px;
        /*-webkit-border-radius: 8px;*/
        /*-moz-border-radius: 8px;*/
        /*border-radius: 8px;*/
    }

    .nut-swiper-slide {
        /*-webkit-border-radius: 8px;*/
        /*-moz-border-radius: 8px;*/
        /*border-radius: 8px;*/
    }

    .coupon-card-list-container {
        margin-top: 10px;

        .coupon-card {
            background: #fff;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            padding: 15px;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;

            .title {
                width: 100%;
                display: flex;
                align-items: center;
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                height: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
            }

            .cover {
                width: 100%;
                height: 151px;
                -webkit-border-radius: 6px;
                -moz-border-radius: 6px;
                border-radius: 6px;
                overflow: hidden;
                margin-top: 10px;

                img {
                    width: 100%;
                    height: 151px;
                }
            }

            .footer {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 10px;

                .product-price {
                    font-size: 10px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    color: #939393;
                    margin-right: 6px;
                    text-decoration: line-through;
                }

                .market-price {
                    display: flex;
                    align-items: center;

                    .rmb {
                        font-size: 14px;
                        color: #C10000;
                        font-family: PingFangSC-Semibold, PingFang SC;
                    }

                    .text {
                        font-size: 16px;
                        color: #C10000;
                        font-family: PingFangSC-Semibold, PingFang SC;
                    }
                }
            }
        }
    }

    .card-titles {
        width: 100%;
        height: 30px;
        display: flex;
        margin-top: 15px;
        align-items: center;

        .image {

            img {
                width: 24px;
                height: 24px;
            }
        }

        .title-text {
            flex: 1;
            display: flex;
            height: 24px;
            line-height: 24px;
            align-items: center;
            font-size: 14px;
            color: #333;
            font-weight: bold;
            font-family: PingFangSC-Semibold, PingFang SC;
        }
    }


</style>